import React from 'react';
import styles from './DarkToggle.module.css'
import { ThemeContext } from './ThemeContext';

export default function DarkToggle() {
  const { colorMode, setColorMode } = React.useContext(ThemeContext);

  if (!colorMode) {
    return null;
  }

  return (
      <div className = {styles.background} role = "button" onClick={ev => {
          setColorMode(colorMode === 'light' ? 'dark' : 'light');
        }} onKeyDown={ev => {
          setColorMode(colorMode === 'light' ? 'dark' : 'light');
        }} tabIndex = {0}>
          <div className = {styles.circle}>
          <object className =  {styles.sun} type="image/svg+xml" data={require('./images/sun.svg')}>Sun</object>
          <object className =  {styles.moon} type="image/svg+xml" data={require('./images/moon.svg')}>Moon</object>
          </div>

    </div>
  );
};

